import formInputFieldPropsMixin from './formInputFieldProps.js';

export default {
  mixins: [formInputFieldPropsMixin],
  props: {
    options: {
      type: Array,
      default: () => [],
    },
    optionValue: {
      type: String,
      default: 'value',
    },
    optionLabel: {
      type: String,
      default: 'label',
    },
  },
};
